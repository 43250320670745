var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex justify-center align-center ma-2"},[_c('v-icon',{attrs:{"large":"","left":"","color":"blue"}},[_vm._v("mdi-file-edit-outline")]),_c('div',{staticClass:"text-h5 pa-4 blue--text darken-2"},[_vm._v("ตรวจสอบจำนวนเวชภัณฑ์")]),_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","large":"","color":"blue darken-2 white--text"}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-plus-thick")]),_vm._v(" อัพเดทจำนวนเวชภัณฑ์ ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"items-per-page":10,"items":_vm.itemList,"headers":_vm.inventoryHeader,"group-by":"genericName","footer-props":{
            'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'
        }},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.status == 0)?_c('div',{staticClass:"orange--text"},[_vm._v(_vm._s(item.quantity)+" "+_vm._s(item.unit))]):_c('div',[_vm._v(_vm._s(item.quantity)+" "+_vm._s(item.unit))])])]}}],null,true)},[(item.status == 0)?_c('snap',[_vm._v("อยู่ในระหว่างการจัดส่ง")]):_c('snap',[_vm._v("เวชภัณฑ์คงคลัง")])],1)]}},{key:"item.tpuName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.tpuName)+" (ล็อต "+_vm._s(item.lotNo)+") ")]}},{key:"group.header",fn:function(ref){
        var group = ref.group;
        var items = ref.items;
        var isOpen = ref.isOpen;
        var toggle = ref.toggle;
return [_c('th',{staticClass:"text-body-1",attrs:{"colspan":"1"}},[_c('v-icon',{ref:group,attrs:{"is-expanded":isOpen},on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus'))]),_vm._v(" "+_vm._s(items[0].genericName)+" ")],1),_c('th',{staticClass:"text-body-1",attrs:{"colspan":"1"}},[(_vm.isPendingIncluded(items))?_c('div',{staticClass:"orange--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.sumQuantity(items))+" "+_vm._s(items[0].unit)+"*")])]}}],null,true)},[_c('v-row',{staticClass:"d-flex flex-row pa-4 align-center justify-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-warehouse")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.onHandCount(items))+" "+_vm._s(items[0].unit))]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-truck")]),_c('span',[_vm._v(_vm._s(_vm.incomingCount(items))+" "+_vm._s(items[0].unit))])],1)],1)],1):_c('div',[_vm._v(" "+_vm._s(_vm.sumQuantity(items))+" "+_vm._s(items[0].unit)+" ")])]),_c('th',{staticClass:"text-body-1",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(items[0].expired)+" ")])]}},{key:"item.location",fn:function(ref){
        var item = ref.item;
return [(item.status)?_c('v-text-field',{attrs:{"label":"ระบุตำแหน่งปัจจุบัน","value":item.location}}):_vm._e()]}},{key:"item.currentStock",fn:function(ref){
        var item = ref.item;
return [(item.status)?_c('v-text-field',{attrs:{"label":"ระบุจำนวนปัจจุบัน"}}):_vm._e()]}}])}),_c('v-textarea',{attrs:{"outlined":"","label":"บันทึกช่วยจำ"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }