<template>
    <v-container>
        <!-- Header -->
        <v-row class="d-flex justify-center align-center ma-2">
            <v-icon large left color="blue">mdi-file-edit-outline</v-icon>
            <div class="text-h5 pa-4 blue--text darken-2">ตรวจสอบจำนวนเวชภัณฑ์</div>
            <v-spacer/>
            <v-btn tile large color="blue darken-2 white--text">
                <v-icon class="pr-2">mdi-plus-thick</v-icon>
                อัพเดทจำนวนเวชภัณฑ์
            </v-btn>
        </v-row>
        <v-divider/>

        <!-- TABLE -->
        <v-data-table :items-per-page="10" :items="itemList" :headers="inventoryHeader" group-by="genericName" :footer-props="{
                'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'
            }">
                <template v-slot:item.quantity="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <div v-if="item.status == 0" class="orange--text">{{item.quantity}} {{item.unit}}</div>
                            <div v-else>{{item.quantity}} {{item.unit}}</div>
                        </span>
                    </template>
                    <snap v-if="item.status == 0">อยู่ในระหว่างการจัดส่ง</snap>
                    <snap v-else>เวชภัณฑ์คงคลัง</snap>
                </v-tooltip>
            </template>

            <template v-slot:item.tpuName="{item}">
                {{item.tpuName}} (ล็อต {{item.lotNo}})
            </template>

            <template v-slot:group.header="{group, items, isOpen, toggle}">
                <th colspan="1" class="text-body-1">
                    <v-icon @click="toggle" :ref="group" :is-expanded="isOpen">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    {{ items[0].genericName }}
                </th>
                <th colspan="1" class="text-body-1">
                    <div v-if="isPendingIncluded(items)" class="orange--text">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" >{{sumQuantity(items)}} {{items[0].unit}}*</span>
                            </template>
                            <v-row class="d-flex flex-row pa-4 align-center justify-center">
                                <v-icon color="white" class="mr-2">mdi-warehouse</v-icon>
                                <span class="mr-2">{{onHandCount(items)}} {{items[0].unit}}</span>
                                <v-icon color="white" class="mr-2">mdi-truck</v-icon>
                                <span>{{incomingCount(items)}} {{items[0].unit}}</span>
                            </v-row>
                        </v-tooltip>
                    </div>
                    <div v-else>
                        {{sumQuantity(items)}} {{items[0].unit}}
                    </div>
                </th>
                <th colspan="3" class="text-body-1">
                    {{items[0].expired}}
                </th>
            </template>
            <template v-slot:item.location="{item}">
                <v-text-field v-if="item.status" label="ระบุตำแหน่งปัจจุบัน" :value="item.location"></v-text-field>
            </template>
            <template v-slot:item.currentStock="{item}">
                <v-text-field v-if="item.status" label="ระบุจำนวนปัจจุบัน"></v-text-field>
            </template>
        </v-data-table>
        <v-textarea outlined label="บันทึกช่วยจำ"></v-textarea>
    </v-container>
</template>
<script>
export default {
    data:()=>{
        return {
            inventoryHeader : [
                {
                    'text':'ชื่อ',
                    'value':'genericName',
                },{
                    'text':'ชื่อ',
                    'value':'tpuName',
                },{
                    'text':'จำนวนคงเหลือ',
                    'value':'quantity',
                },{
                    'text':'วันหมดอายุ',
                    'value':'expired',
                },{
                    'text':'ตำแหน่งที่ตั้งปัจจุบัน',
                    'value':'location',
                },{
                    'text':'จำนวนคงคลังที่นับได้',
                    'value':'currentStock',
                }
            ],
            itemList : [
                {
                    'genericName':'Paracetamol 500 mg',
                    'tpuName':'Zara',
                    'dosage':'500 mg',
                    'packingSize':'500',
                    'unit':'กล่อง',
                    'lotNo':'1235',
                    'quantity':'5',
                    'expired':'15 MAR 2020',
                    'location':'EG3 - 6450',
                    'status':1
                },{
                    'genericName':'Paracetamol 500 mg',
                    'tpuName':'Tylenol',
                    'dosage':'500 mg',
                    'packingSize':'500',
                    'unit':'กล่อง',
                    'lotNo':'1785',
                    'quantity':'2',
                    'expired':'22 JAN 2021',
                    'location':'EG3 - 6450',
                    'status':1
                },{
                    'genericName':'Paracetamol 500 mg',
                    'tpuName':'Tylenol',
                    'dosage':'500 mg',
                    'packingSize':'500',
                    'unit':'กล่อง',
                    'lotNo':'2654',
                    'quantity':'2',
                    'expired':'2 MAY 2022',
                    'location':'EG3 - 6450',
                    'status':1
                },{
                    'genericName':'Amoxycilin 500 mg',
                    'tpuName':'Xylomox-500',
                    'dosage':'500 mg',
                    'packingSize':'500',
                    'unit':'กล่อง',
                    'lotNo':'7458',
                    'quantity':'7',
                    'expired':'14 JAN 2022',
                    'location':'EG3 - 6450',
                    'status':1
                },{
                    'genericName':'Amoxycilin 250 mg',
                    'tpuName':'Tanoxy-250',
                    'dosage':'250 mg',
                    'packingSize':'1000',
                    'unit':'กล่อง',
                    'lotNo':'4756',
                    'quantity':'15',
                    'expired':'17 OCT 2022',
                    'location':'EG3 - 6450',
                    'status':1
                },
            ],
        }
    },
    methods:{
        applyRouterLink(name){
            this.$router.push({name})
        },
        sumQuantity(items){
            var sumQuan = 0
            items.forEach(e => {
                sumQuan += parseInt(e.quantity)
            });
            return sumQuan
        },
        isPendingIncluded(items){
            var isPending = false
            items.forEach(e => {
                if(e.status == 0) {
                    isPending = true
                }
            })
            return isPending
        },
        onHandCount(items){
            var cnt = 0
            items.forEach(e => {
                if(e.status == 1) {
                    cnt += parseInt(e.quantity)
                }
            })
            return cnt
        },
        incomingCount(items){
            var cnt = 0
            items.forEach(e => {
                if(e.status == 0) {
                    cnt += parseInt(e.quantity)
                }
            })
            return cnt
        }
        ,getInventory(){
            console.log("get Inv")
        }
    },
    computed:{
    },
    mounted() {
        this.getInventory()
    },
}
</script>